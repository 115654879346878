@import "../../shared-frontend/src/utils/fonts/font.scss";
@import "../../shared-frontend/src/utils/fonts/design_fonts.scss";
@import "../../shared-frontend/src/index.scss";
@import "~react-toastify/dist/ReactToastify.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Titillium+Web:wght@200;400&display=swap");

html,
body {
  width: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

button:focus {
  outline: none;
}

// react-toastify packages styles
.Toastify__toast-body {
  font-size: 1rem;
}

:root {
  --toastify-color-success: #afbdac;
  --toastify-text-color-success: #404040;
  --toastify-color-progress-success: #404040;
}

.Toastify__toast--success {
  .Toastify__close-button {
    color: var(--toastify-color-progress-success);
  }
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

// FabricJs custom css
// https://github.com/fabricjs/fabric.js/issues/2904
textarea[data-fabric-hiddentextarea] {
  top: 0;
}
