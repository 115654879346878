/*----------------------------------------*/
/*  Font ABeeZee
/*----------------------------------------*/
/* latin */
@font-face {
  font-family: "ABeeZee";
  font-weight: 400;
  font-style: italic;

  font-display: swap;
  src: local("ABeeZee Italic"), local("ABeeZee-Italic"),
    url(https://asset.paperdateco.com/font/abeezee-italic.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "ABeeZee";
  font-weight: 400;
  font-style: normal;

  font-display: swap;
  src: local("ABeeZee Regular"), local("ABeeZee-Regular"),
    url(https://asset.paperdateco.com/font/abeezee.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/*----------------------------------------*/
/*  Font Futura
  /*----------------------------------------*/

@font-face {
  font-family: "Futura";
  font-weight: 300;
  font-style: normal;

  font-display: swap;
  src: url(https://asset.paperdateco.com/font/futura-lig-webfont.eot);
  src: url(https://asset.paperdateco.com/font/futura-lig-webfont.eot?#iefix)
      format("embedded-opentype"),
    url(https://asset.paperdateco.com/font/futura-lig-webfont.woff2)
      format("woff2"),
    url(https://asset.paperdateco.com/font/futura-lig-webfont.woff)
      format("woff"),
    url(https://asset.paperdateco.com/font/futura-lig-webfont.svg#futuraligregular)
      format("svg");
}

@font-face {
  font-family: "Futura";
  font-weight: 400;
  font-style: normal;

  font-display: swap;
  src: url(https://asset.paperdateco.com/font/futura-med-webfont.eot);
  src: url(https://asset.paperdateco.com/font/futura-med-webfont.eot?#iefix)
      format("embedded-opentype"),
    url(https://asset.paperdateco.com/font/futura-med-webfont.woff2)
      format("woff2"),
    url(https://asset.paperdateco.com/font/futura-med-webfont.woff)
      format("woff"),
    url(https://asset.paperdateco.com/font/futura-med-webfont.svg#futuramedregular)
      format("svg");
}

@font-face {
  font-family: "Futura";
  font-weight: 600;
  font-style: normal;

  font-display: swap;
  src: url(https://asset.paperdateco.com/font/futura-bol-webfont.eot);
  src: url(https://asset.paperdateco.com/font/futura-bol-webfont.eot?#iefix)
      format("embedded-opentype"),
    url(https://asset.paperdateco.com/font/futura-bol-webfont.woff2)
      format("woff2"),
    url(https://asset.paperdateco.com/font/futura-bol-webfont.woff)
      format("woff"),
    url(https://asset.paperdateco.com/font/futura-bol-webfont.svg#futurabold)
      format("svg");
}

/*----------------------------------------*/
/*  Font Marcellus
  /*----------------------------------------*/
/* latin-ext */
@font-face {
  font-family: "Marcellus";
  font-weight: 400;
  font-style: normal;

  font-display: swap;
  src: local("Marcellus"), local("Marcellus-Regular"),
    url(https://asset.paperdateco.com/font/marcellus-latin-ext.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Marcellus";
  font-weight: 400;
  font-style: normal;

  font-display: swap;
  src: local("Marcellus"), local("Marcellus-Regular"),
    url(https://asset.paperdateco.com/font/marcellus-latin.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* Amertha */
@font-face {
  font-family: "amertha";
  font-weight: 400;
  font-style: normal;

  font-display: swap;
  src: local("amertha"),
    url(https://asset.paperdateco.com/font/Amertha.ttf) format("truetype");
}

/*----------------------------------------*/
/*  Font Modesty
  /*----------------------------------------*/
@font-face {
  font-family: "Modesty";
  font-weight: normal;
  font-style: normal;

  font-display: swap;
  src: url(https://asset.paperdateco.com/font/ModestyRegularByMCT.eot);
  src: url(https://asset.paperdateco.com/font/ModestyRegularByMCT.eot?#iefix)
      format("embedded-opentype"),
    url(https://asset.paperdateco.com/font/ModestyRegularByMCT.woff2)
      format("woff2"),
    url(https://asset.paperdateco.com/font/ModestyRegularByMCT.woff)
      format("woff"),
    url(https://asset.paperdateco.com/font/ModestyRegularByMCT.ttf)
      format("truetype"),
    url(https://asset.paperdateco.com/font/ModestyRegularByMCT.svg#ModestyRegularByMCT)
      format("svg");
}
@font-face {
  font-family: "Futurist Fixed-width";
  font-style: normal;
  font-weight: normal;
  src: local("Futurist Fixed-width"),
    url(https://asset.paperdateco.com/font/FUTRFW.woff) format("woff");
}

@font-face {
  font-family: "Cormorant Garamond";
  font-weight: normal;
  font-weight: normal;
  font-display: swap;
  src: url(https://asset.paperdateco.com/font/CormorantGaramond-Regular.ttf)
    format("truetype");
}

@font-face {
  font-family: "Cormorant Garamond";
  font-weight: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://asset.paperdateco.com/font/CormorantGaramond-Light.ttf)
    format("truetype");
}

@font-face {
  font-family: "Cormorant Garamond";
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url(https://asset.paperdateco.com/font/CormorantGaramond-Italic.ttf)
    format("truetype");
}

@font-face {
  font-family: "Cormorant Garamond";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url(https://asset.paperdateco.com/font/CormorantGaramond-Bold.ttf)
    format("truetype");
}

@font-face {
  font-family: "Cormorant Garamond";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://asset.paperdateco.com/font/CormorantGaramond-BoldItalic.ttf)
    format("truetype");
}
